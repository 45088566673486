dialog {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  color: #000;
  background: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  background: #0000001a;
  position: fixed;
  inset: 0;
}

._dialog_overlay {
  position: fixed;
  inset: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

/*# sourceMappingURL=index.3bef3d6c.css.map */
